<template>
  <div class="section-invite mt-md-0 py-3 bg-white shadow-sm">
    <HeaderTop title="パスワード変更" class="ml-3 mr-4 ml-xxl-4 mb-4" :isHideViewAll='true'></HeaderTop>
    <b-form
      class="form-inputs form-password px-3 px-xl-4 px-xxl-5"
      novalidate
      @submit.prevent="onSubmitChangePassword"
    >
      <b-row>
        <b-col md="12" lg="4"></b-col>
        <b-col md="12" lg="8">
          <transition-alert>
            <template v-if="msgErrors.length">
              <b-alert
                :show="dismissCountDown"
                dismissible
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChange"
                variant="danger"
              >
                <span
                  class="d-block"
                  v-for="(msg, index) in msgErrors"
                  :key="index"
                  >{{ msg }}</span
                >
              </b-alert>
            </template>
          </transition-alert>
          <transition-alert>
            <template v-if="msgSuccess">
              <b-alert
                :show="dismissCountDown"
                dismissible
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChange"
                variant="success"
              >
                {{ msgSuccess }}
              </b-alert>
            </template>
          </transition-alert>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col md="12" lg="4">
          <label for="current-password" class="font-weight-bold">現在のパスワード</label>
        </b-col>
        <b-col md="12" lg="8">
          <b-form-input
            id="current-password"
            autocomplete="off"
            type="password"
            v-model="changePasswordForm.password"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="py-2 py-md-3">
        <b-col md="12" lg="4">
          <label for="new-password" class="font-weight-bold">新しいパスワード</label>
        </b-col>
        <b-col md="12" lg="8">
          <b-form-input
            id="new-password"
            autocomplete="off"
            type="password"
            v-model="changePasswordForm.newPassword"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="py-2 pb-md-3">
        <b-col md="12" lg="4">
          <label for="new-confirm-password" class="font-weight-bold">新しいパスワード（確認用）</label>
        </b-col>
        <b-col md="12" lg="8">
          <b-form-input
            id="new-confirm-password"
            autocomplete="off"
            type="password"
            v-model="changePasswordForm.newPasswordConfirmed"
          ></b-form-input>

          <b-button class="btn-slash mt-4" type="submit" :disabled="isSubmitted"
            ><span class="position-relative">更新</span>
            <esports-loading-button
              v-if="isSubmitted"
              width="30px"
              height="30px"
              outerBorder="#333"
              innerBorder="#eee"
          /></b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { dismissCount } from "@/mixins";
import HeaderTop from "./MyTop/HeaderTop";

export default {
  name: "TabChangePassword",
  components: { HeaderTop },
  data() {
    return {
      isSubmitted: false,
      msgErrors: [],
      msgSuccess: null,
      changePasswordForm: {
        password: "",
        newPassword: "",
        newPasswordConfirmed: ""
      },
    };
  },
  watch: {
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  mixins: [dismissCount],
  methods: {
    async onSubmitChangePassword() {
      this.isSubmitted = true;
      this.msgSuccess = null;

      const result = await this.$store
        .dispatch("user/changePassword", this.changePasswordForm)
        .catch((err) => {
          this.isSubmitted = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      this.isSubmitted = false;

      if (result) {
        this.msgSuccess = result.message;
        this.msgErrors = [];
        this.changePasswordForm.password = "";
        this.changePasswordForm.newPassword = "";
        this.changePasswordForm.newPasswordConfirmed = "";
      }
    },
  },
};
</script>