<template>
  <div class="user-content-first">
    <h2 class="font-weight-bold">
      <span>対象アカウント</span>
    </h2>
    <b-form
      class="form-inputs px-3 px-xl-4 px-xxl-5 mt-3 mt-xxl-5"
      novalidate
      @submit.prevent="onSubmitSendOtp"
    >
      <b-row>
        <b-col md="12" lg="3"></b-col>
        <b-col md="12" lg="9">
          <transition-alert>
            <template v-if="msgErrors.length">
              <b-alert
                :show="dismissCountDown"
                dismissible
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChange"
                variant="danger"
              >
                <span
                  class="d-block"
                  v-for="(msg, index) in msgErrors"
                  :key="index"
                  >{{ msg }}</span
                >
              </b-alert>
            </template>
          </transition-alert>
          <transition-alert>
            <template v-if="msgSuccess">
              <b-alert
                :show="dismissCountDown"
                dismissible
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChange"
                variant="success"
              >
                {{ msgSuccess }}
              </b-alert>
            </template>
          </transition-alert>
        </b-col>
      </b-row>
      <b-row class="my-1 mb-xxl-4 pb-xxl-3">
        <b-col md="12" lg="3">
          <label for="input-email" class="font-weight-bold">ユーザー名</label>
        </b-col>
        <b-col md="12" lg="9">
          <div class="input-form" v-if="user">{{ user.username }}</div>
        </b-col>
      </b-row>
      <b-row class="my-1 mb-xxl-4 pb-xxl-3">
        <b-col md="12" lg="3">
          <label class="font-weight-bold">メールアドレス</label>
        </b-col>
        <b-col md="12" lg="9">
          <div class="input-form" v-if="user">{{ user.email }}</div>
        </b-col>
      </b-row>

      <b-row class="py-2 py-md-3">
        <b-col md="12" lg="3">
          <label for="input-message" class="font-weight-bold">退会理由</label>
        </b-col>
        <b-col md="12" lg="9">
          <div class="input-form mb-3 mb-xxl-5 pb-3 pb-xxl-4">
            {{ userForm && userForm.reason ? userForm.reason : ''}}
          </div>

          <div class="d-md-flex justify-content-between">
            <b-button class="btn-slash btn-cancel m-1 m-md-0" @click="redirectPrevStep">
              <span class="position-relative">戻る</span>
            </b-button>
            <b-button
              class="btn-slash m-1 m-md-0"
              type="submit"
              :disabled="isSubmitted"
              ><span class="position-relative">退会する</span>
              <esports-loading-button
                v-if="isSubmitted"
                width="30px"
                height="30px"
                outerBorder="#333"
                innerBorder="#eee"
            /></b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { dismissCount } from "@/mixins";
import { mapGetters } from "vuex";

export default {
  name: "UserRemoveSecond",
  props: {
    formRemoveData: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      isSubmitted: false,
      msgErrors: [],
      msgSuccess: null,
      userForm: {}
    };
  },
  watch: {
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mixins: [dismissCount],
  mounted() {
    this.scrollToTop();
    if(this.formRemoveData)
      this.userForm = { ...this.formRemoveData };
  },
  methods: {
    async onSubmitSendOtp() {
      this.isSubmitted = true;
      this.msgSuccess = null;

      const result = await this.$store
        .dispatch("user/sendOtp", {email: this.userForm.email})
        .catch((err) => {
          this.isSubmitted = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      this.isSubmitted = false;

      if (result) {
        this.msgErrors = [];

        this.$emit("activeComponent", {
          user: this.userForm,
          name: "UserRemoveVerifyCode",
        });
      }
    },
    redirectPrevStep() {
      this.$emit("activeComponent", {
          user: this.userForm,
          name: "UserRemoveFirst",
      });
    }
  },
};
</script>