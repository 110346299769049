<template>
  <b-container class="mt-4 mt-xxl-5 login-page register-page contact-form pb-5">
    <b-row class="justify-content-center w-100">
      <b-col md="10" lg="8">
        <b-card-group class="align-items-center h-100 verify-code">
          <b-card no-body class="border-0 rounded-0">
            <b-card-body class="py-0 px-2 px-lg-5 mt-3">
              <b-form class="p-3 mt-xxl-5 p-lg-3 mb-lg-4" novalidate>
                <h5 class="text-center mb-0">
                  <b>退会の手続きが完了しました。</b><br />
                  <b>ご利用いただき誠に有り難うございました。</b>
                </h5>
                <p class="text-center py-4 mb-xl-4 mt-xl-3">
                  <b-button class="btn-outline btn-home" @click="redirect">{{
                    $t("general_inputs.btn_home")
                  }}</b-button>
                </p>
              </b-form>
            </b-card-body>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "UserRemoveThird",
  props: {
    formContactData: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      isSubmitted: false,
    };
  },
  methods: {
    redirect() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>