<template>
  <div class="mt--3" ref="bodyContent">
    <BuyVote :ticketHistory='ticketHistory' :isViewAll="true" />
    <template>
      <p class="text-center position-relative" v-if="isLazyLoading">
        <esports-loading-button width="30px" height="30px" />
      </p>
    </template>
    <esports-loading-button
      class="overlay-content-tab-page"
      width="80"
      height="80"
      v-if="isLoading"
    />
  </div>
</template>

<script>
import BuyVote from "./MyTop/BuyVote";
export default {
  name: "TabBuyVote",
  data() {
      return {
        isLoading: false,
        isLazyLoading: false,
        ticketHistory: [],
        dataContent: [],
        page: 1,
        perPage: 20,
      }
  },
  components: { BuyVote },
  async mounted() {
    this.isLoading = true;

    this.$nextTick(() => {
      window.addEventListener("scroll", this.onScroll);
      this.onScroll();
    });

    await Promise.race([
      this.getAllHistoryTicket({ page: this.page, perPage: this.perPage }),
    ]);
    this.isLoading = false;
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  computed: {
    ticketLazyLoad() {
      return this.ticketHistory || [];
    },
  },
  methods: {
    async getAllHistoryTicket(params) {
      const result = await this.$store
        .dispatch("vote/getAllHistoryTicket", params)
        .catch((err) => {
          console.log(err);
        });

      if(result) {
        this.ticketHistory = result;
        this.dataContent = result;
      }
    },
    async onScroll() {
      const vm = this;
      if (vm.$refs.bodyContent) {
        let bottom = vm.$refs.bodyContent.getBoundingClientRect().bottom;
        if (
          bottom < window.scrollY &&
          !this.isLazyLoading &&
          this.dataContent.length
        ) {

          this.isLazyLoading = true;
          this.page += 1;

          this.dataContent = await this.$store
            .dispatch("vote/getAllHistoryTicket", {
              page: this.page,
              perPage: this.perPage,
            })
            .catch((err) => {
              console.log(err);
              this.isLazyLoading = false;
            });

          if (this.dataContent.length) {
            let dataList = this.dataContent;
            this.ticketHistory = this.ticketHistory.concat(dataList);
          }

          this.isLazyLoading = false;
        }
      }
    },
  },
};
</script>