<template>
  <div class="section-invite section-register-gamer mt-3 mt-md-0 py-3 py-xxl-5 bg-white shadow-sm">
    <component
      @activeComponent="activeComponent"
      @removeUser="removeUser"
      :formRemoveData="formRemoveData"
      :is="currentTabComponent"
    ></component>
  </div>
</template>

<script>
import UserRemoveFirst from './UserRemove/UserRemoveFirst';
import UserRemoveSecond from './UserRemove/UserRemoveSecond';
import UserRemoveVerifyCode from './UserRemove/UserRemoveVerifyCode';

export default {
  name: "TabRemoveUser",
  components: {
    UserRemoveFirst,
    UserRemoveSecond,
    UserRemoveVerifyCode
  },
  data() {
    return {
      formRemoveData: null,
      currentTabComponent: UserRemoveFirst,
    };
  },
  methods: {
    activeComponent(payload) {
      this.formRemoveData = payload.user;
      this.currentTabComponent = payload.name;
    },
    removeUser() {
      this.$emit('removeUser');
    }
  },
};
</script>