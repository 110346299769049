<template>
  <div class="user-content-first">
    <h2 class="font-weight-bold">
      <span>退会フォーム</span>
    </h2>
    <b-form
      class="form-inputs px-3 px-xl-4 px-xxl-5"
      novalidate
      @submit.prevent="onSubmitInvite"
    >
      <b-row class="mb-xxl-5">
        <b-col>
          <div class="alert alert-danger alert-danger-custom">
            <span class="d-block"
              >退会すると以下のデータが削除され、今後ログインする事はできなくなります。</span
            >
            <span class="d-block"
              >削除されたデータは二度と復旧することはできませんが、本当に削除しますか？</span
            >
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" lg="3"></b-col>
        <b-col md="12" lg="9">
          <transition-alert>
            <template v-if="msgErrors.length">
              <b-alert
                :show="dismissCountDown"
                dismissible
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChange"
                variant="danger"
              >
                <span
                  class="d-block"
                  v-for="(msg, index) in msgErrors"
                  :key="index"
                  >{{ msg }}</span
                >
              </b-alert>
            </template>
          </transition-alert>
          <transition-alert>
            <template v-if="msgSuccess">
              <b-alert
                :show="dismissCountDown"
                dismissible
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChange"
                variant="success"
              >
                {{ msgSuccess }}
              </b-alert>
            </template>
          </transition-alert>
        </b-col>
      </b-row>
      <b-row class="my-1 mb-xxl-3 pb-xxl-3">
        <b-col md="12" lg="3">
          <label for="input-email" class="font-weight-bold">ユーザー名</label>
        </b-col>
        <b-col md="12" lg="9">
          <div class="input-form" v-if="user">{{ user.username }}</div>
        </b-col>
      </b-row>
      <b-row class="my-1 mb-xxl-4 pb-xxl-3">
        <b-col md="12" lg="3">
          <label class="font-weight-bold">メールアドレス</label>
        </b-col>
        <b-col md="12" lg="9">
          <div class="input-form" v-if="user">{{ user.email }}</div>
        </b-col>
      </b-row>
      <!-- <b-row class="my-1 mb-xxl-4 pb-xxl-3">
        <b-col md="12" lg="3">
          <label for="current-password" class="font-weight-bold"
            >現在のパスワード</label
          >
        </b-col>
        <b-col md="12" lg="9">
          <b-form-input
            id="current-password"
            autocomplete="off"
            type="password"
            v-model="userForm.password"
          ></b-form-input>
        </b-col>
      </b-row> -->

      <b-row class="py-2 py-md-3">
        <b-col md="12" lg="3">
          <label for="input-message" class="font-weight-bold">退会理由</label>
        </b-col>
        <b-col md="12" lg="9">
          <b-form-textarea
            rows="3"
            v-model="userForm.reason"
            no-resize
          ></b-form-textarea>
        </b-col>
      </b-row>

      <b-row class="py-2 py-md-3">
        <b-col cols="12">
          <p class="text-note mt-3 py-3 pb-xxl-5 my-xxl-3 mb-xxl-5">
            <span class="pb-1"><b>退会するにあたって</b></span>
            <span
              >退会処理をされますと、サイトに登録することでご利用になれた全てのサービスがご利用できなくなります。</span
            >
            <span
              >また、保存したお気に入りや、各種履歴等のデータにアクセスできなくなります。</span
            >
            <span>取得された票やドネトも全て無効となります。</span>
            <span
              >退会処理後にアカウントを元に戻すことはできませんのでお気をつけください。</span
            >
          </p>
        </b-col>
        <b-col md="12" lg="3">&nbsp;</b-col>
        <b-col md="12" lg="9">
          <div class="d-md-flex justify-content-between">
            <b-button
              @click="resetForm"
              class="btn-slash btn-cancel m-1 m-md-0"
            >
              <span class="position-relative">キャンセル</span>
            </b-button>
            <b-button
              class="btn-slash m-1 m-md-0"
              type="submit"
              :disabled="isSubmitted"
              ><span class="position-relative">確認する</span>
              <esports-loading-button
                v-if="isSubmitted"
                width="30px"
                height="30px"
                outerBorder="#333"
                innerBorder="#eee"
            /></b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { dismissCount } from "@/mixins";
import { mapGetters } from "vuex";

export default {
  name: "UserRemoveFirst",
  props: {
    formRemoveData: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      isSubmitted: false,
      msgErrors: [],
      msgSuccess: null,
      userForm: {
        email: "",
        username: "",
        reason: "",
        otpCode: "",
        isValidate: true,
        loginPlatform: 1
      },
    };
  },
  watch: {
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  mixins: [dismissCount],
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    if (this.formRemoveData) this.userForm = { ...this.formRemoveData };
    this.userForm.email = this.user.email;
    this.userForm.username = this.user.username;
  },
  methods: {
    async onSubmitInvite() {
      this.isSubmitted = true;
      this.msgSuccess = null;

      const result = await this.$store
        .dispatch("user/removeUser", this.userForm)
        .catch((err) => {
          this.isSubmitted = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
          this.scrollToTop();
        });

      this.isSubmitted = false;

      if (result) {
        this.msgErrors = [];

        this.$emit("activeComponent", {
          user: this.userForm,
          name: "UserRemoveSecond",
        });
      }
    },
    resetForm() {
      this.userForm.reason = this.formRemoveData
        ? this.formRemoveData.reason
        : "";
      this.scrollToTop();
    },
  },
};
</script>