<template>
  <div class="mt--3">
    <HistoryPoint :pointHistory="pointHistory" :isViewAll="true" />
    <esports-loading-button
        class="overlay-content-tab-page"
        width="80"
        height="80"
        v-if="isLoading"
    />
  </div>
</template>

<script>
import HistoryPoint from "./MyTop/HistoryPoint";
export default {
  name: "TabPointHistory",
  data() {
      return {
        isLoading: false,
        pointHistory: []
      }
  },
  components: { HistoryPoint },
  async mounted() {
    this.isLoading = true
    await Promise.race([
      this.getAllHistoryPoint(),
    ]);
    this.isLoading = false;
  },
  methods: {
    async getAllHistoryPoint() {
      const result = await this.$store
        .dispatch("vote/getAllHistoryPoint")
        .catch((err) => {
          console.log(err);
        });

      if(result) {
        let pointHistoryWinner = result.map(item => {
          item.numberPoints = item.positiveNumber ? `${this.formatPoint(item.numberPoints)}pt` : `-${this.formatPoint(item.numberPoints)}pt`;
          return item;
        });

        this.pointHistory = pointHistoryWinner;
      }
    },
    async getHistoryPointExchange() {
      const result = await this.$store
        .dispatch("point_exchange/getPointHistory", {page: 1, perPage: 500})
        .catch((err) => {
          console.log(err);
        });

      if(result) {

        let pointsHistoryExchange = result.map(item => {
          item.numberPoints = `-${this.formatPoint(item.numberPoints)}pt`;
          item.eventName = item.cardName;
          return item;
        });

        this.pointHistory = this.pointHistory.concat(pointsHistoryExchange);
      }
    },
  },
};
</script>