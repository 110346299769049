<template>
  <div>
    <Avatar :dataMyPage='dataMyPage' @redirectPointHistory="redirectPointHistory" />
    <HistoryVoted :voteHistory='voteHistory' @redirectPointHistory="redirectPointHistory" />
    <BuyVote :ticketHistory="ticketHistory" @redirectPointHistory="redirectPointHistory" />
    <HistoryPoint :pointHistory="pointHistory" @redirectPointHistory="redirectPointHistory" />
    <esports-loading-button
        class="overlay-content-tab-page"
        width="80"
        height="80"
        v-if="isLoading"
    />
  </div>
</template>

<script>
import Avatar from "./MyTop/Avatar";
import HistoryVoted from "./MyTop/HistoryVoted";
import BuyVote from "./MyTop/BuyVote";
import HistoryPoint from "./MyTop/HistoryPoint";
export default {
  name: "TabTop",
  data() {
      return {
        dataMyPage: null,
        isLoading: false,
        voteHistory: [],
        ticketHistory: [],
        pointHistory: [],
      }
  },
  components: { Avatar, HistoryVoted, BuyVote, HistoryPoint },
  async mounted() {
    this.isLoading = true
    await Promise.race([
        this.getMyPage(),
        this.getHistoryVote(),
        this.getHistoryTicket(),
        this.getHistoryPointExchange()
    ]);
    this.isLoading = false;
  },
  methods: {
    async getMyPage() {
      const result = await this.$store
        .dispatch("user/getMyPage")
        .catch((err) => {
          console.log(err);
        });

      if(result) {
        this.dataMyPage = result;
        this.$store.commit('auth/updateUserProfile', result);
      }
    },
    async getHistoryVote() {
      const result = await this.$store
        .dispatch("vote/getHistoryVote")
        .catch((err) => {
          console.log(err);
        });

      if(result) {
        this.voteHistory = result.map(item => {
          item.picture = item.picture ? `${process.env.VUE_APP_BACKEND + item.picture}` : null;
          return item;
        });
      }
    },
    async getHistoryTicket() {
      const result = await this.$store
        .dispatch("vote/getHistoryTicket", { page: 1, perPage: 5})
        .catch((err) => {
          console.log(err);
        });

      if(result) {
        this.ticketHistory = result;
      }
    },
    async getHistoryPoint() {
      const result = await this.$store
        .dispatch("vote/getHistoryPoint")
        .catch((err) => {
          console.log(err);
        });

      if(result) {
        let pointHistoryWinner = result.map(item => {
          item.numberPoints = `${this.formatPoint(item.numberPoints)}pt`;
          return item;
        });

        if(!pointHistoryWinner.length) return;

        if(pointHistoryWinner.length > 5) {
          this.pointHistory = this.pointHistory.concat(pointHistoryWinner.slice(0, 2));  
          return;
        }

        this.pointHistory = this.pointHistory.concat(pointHistoryWinner);
      }
    },
    async getHistoryPointExchange() {
      const result = await this.$store
        .dispatch("point_exchange/getPointHistory", {page: 1, perPage: 5})
        .catch((err) => {
          console.log(err);
        });

      if(result) {

        let pointsHistoryExchange = result.map(item => {
          item.numberPoints = item.positiveNumber ? `${this.formatPoint(item.numberPoints)}pt` : `-${this.formatPoint(item.numberPoints)}pt`;
          return item;
        });

        this.pointHistory = pointsHistoryExchange;
      }
    },
    redirectPointHistory(tab) {
      this.$emit('redirectPointHistory', tab);
    }
  },
};
</script>