<template>
  <div
    class="
      section-history
      shadow-sm
      bg-white
      mt-3 mt-xxl-4
      py-3
      pt-xxl-4
      position-relative
    "
  >
    <HeaderTop
      title="投票履歴"
      class="ml-3 mr-4 ml-xxl-4 mb-3"
      :isHideViewAll="true"
    >
      <b-link
        v-if="!isViewAll"
        slot="href"
        class="mr-xxl-3"
        @click="redirectPointHistory"
        title="View All"
        >View All</b-link
      >
    </HeaderTop>
    <div class="table-content">
      <b-table
        hover
        responsive
        :items="voteHistory"
        class="position-relative"
        :fields="fields"
      >
        <template #cell(createdDate)="row">
          {{ formatDate(row.item.createdDate) }}
        </template>
        <template #cell(numberTickets)="row">
          {{ formatPoint(row.item.numberTickets)
          }}<span class="label-ticket">票</span>
        </template>
        <template #cell(picture)="row">
          <div class="d-flex align-items-center">
            <b-avatar
              :src="row.item.picture"
              v-if="row.item.picture"
              class="mr-2 mr-xxl-3"
            ></b-avatar>
            <b-avatar
              :text="letterName(row.item.gamerName)"
              v-else
              class="mr-2 mr-xxl-3"
            ></b-avatar>
            <span
              class="game-title-avatar"
              :title="row.item.gamerName"
              >{{ truncateText(row.item.gamerName, 30) }}</span
            >
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import HeaderTop from "./HeaderTop";
export default {
  name: "HistoryVoted",
  components: { HeaderTop },
  props: {
    voteHistory: {
      type: [Array],
      default: () => [],
    },
    isViewAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "createdDate",
          label: "日時",
          class: "w-25 text-nowrap",
        },
        {
          key: "eventName",
          label: "大会名",
          class: "w-30 w-mobile",
        },
        {
          key: "picture",
          label: "選手名",
          class: "avatar",
        },
        {
          key: "numberTickets",
          label: "応援数",
          class: "text-nowrap",
        },
      ],
    };
  },
  methods: {
    redirectPointHistory() {
      this.$emit("redirectPointHistory", 1);
    },
  },
};
</script>