<template>
  <div
    class="
      section-avatar
      d-flex
      align-items-center
      flex-column flex-lg-row
      shadow-sm
      px-3 px-xxl-5
      mt-3 mt-md-0
    "
  >
    <b-form-file accept="image/*" capture v-model="file">
      <template #placeholder>
        <div v-if="!avatarUser">
          <b-overlay
            :show="isSubmitted"
            class="d-inline-block"
            rounded="circle"
          >
            <b-avatar
              variant="primary"
              :text="letterName(user.username)"
              class="align-baseline"
              v-if="user && user.username"
            ></b-avatar>
          </b-overlay>
        </div>
        <div v-else>
          <b-overlay
            :show="isSubmitted"
            class="d-inline-block"
            rounded="circle"
          >
            <b-img
              thumbnail
              rounded="circle"
              fluid
              :src="avatarUser"
              alt=""
            ></b-img>
          </b-overlay>
        </div>
        <i class="fa fa-camera" aria-hidden="true"></i>
      </template>
      <template #file-name>
        <div v-if="avatarUser">
          <b-overlay
            :show="isSubmitted"
            class="d-inline-block"
            rounded="circle"
          >
            <b-img
              thumbnail
              rounded="circle"
              fluid
              :src="avatarUser"
              alt=""
            ></b-img>
          </b-overlay>
        </div>
        <i class="fa fa-camera" aria-hidden="true"></i>
      </template>
    </b-form-file>
    <div class="content-points">
      <h4
        class="pt-3 pt-lg-0 position-relative d-md-flex username-avatar"
        v-if="user"
        
      >
        <span @click="changeUsername" v-if="!isEditUsername">{{
          user.username || ""
        }}</span>
        <b-form-input
          v-else
          type="text"
          v-model="username"
          maxLength="60"
          ref="username"
          @blur="handleUpdateUsername"
          :disabled="isUploadAvatar"
          :style="{width: '200px'}"
        ></b-form-input>
        <esports-loading-button
          width="20px"
          v-if="isUploadAvatar"
          height="20px"
        />
        <i class="fa fa-pencil" aria-hidden="true" v-if="!isEditUsername"></i>
      </h4>
      <p v-if="user">{{ user.email || "" }}</p>
      <b-card-group deck>
        <b-card>
          <div class="content-body">
            <h4>投票チケット</h4>
            <h1>
              <span class="num" v-if="dataMyPage">{{
                formatPoint(dataMyPage.currentTickets)
              }}</span
              ><span class="text">票</span>
            </h1>
          </div>
          <b-button v-if="user" title="票をGET" target="_blank" :href="`${getSkyFlatAds() + user.hashUserId}`" class="btn-slash"
            ><span class="position-relative">票をGET</span></b-button
          >
        </b-card>

        <b-card>
          <div class="content-body">
            <h4>獲得ドネト</h4>
            <h1>
              <span class="num" v-if="dataMyPage">{{
                formatPoint(dataMyPage.currentPoints)
              }}</span
              ><span class="text">pt</span>
            </h1>
          </div>
          <b-button title="商品と交換" class="btn-slash" href="#"
            ><span class="position-relative" @click="$emit('redirectPointHistory', 4)">商品と交換</span></b-button
          >
        </b-card>
      </b-card-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Avatar",
  props: {
    dataMyPage: {
      type: [Object],
      default: () => null,
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    avatarUser() {
      return this.user?.avatar;
    },
  },
  data() {
    return {
      isSubmitted: false,
      file: null,
      msgErrors: [],
      urlAvatar: null,
      isEditUsername: false,
      username: null,
      isUploadAvatar: false,
      anchor: process.env.VUE_APP_ADVERTISEMENT,
    };
  },
  watch: {
    file(nVal) {
      if (nVal) {
        this.updateAvatar(nVal);
      }
    },
  },
  methods: {
    async updateAvatar(file) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("user/changeAvatar", { file: file })
        .catch((err) => {
          this.isSubmitted = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }

          this.makeToast("danger", this.msgErrors[0]);
        });

      if (result) {
        setTimeout(() => {
          this.isSubmitted = false;
        }, 1000);

        this.file = null;
        this.urlAvatar = `${process.env.VUE_APP_BACKEND + result.picture}`;

        this.$store.commit("auth/updateAvatar", this.urlAvatar);
        //this.makeToast("success", result.message);
      }
    },
    changeUsername() {
      this.isEditUsername = true;
      this.username = this.user.username;

      this.$nextTick(() => {
        this.$refs.username.focus();
      });
    },
    async handleUpdateUsername() {

      if (this.username === this.user.username) {
        this.isEditUsername = false;
        return;
      }

      this.isUploadAvatar = true;

      const result = await this.$store
        .dispatch("user/updateUsername", { username: this.username })
        .catch((err) => {
          this.isEditUsername = false;
          this.isUploadAvatar = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }

          this.makeToast("danger", this.msgErrors[0]);
        });

      if (result) {
        this.isUploadAvatar = false;
        this.isEditUsername = false;
        this.$store.commit("auth/updateUsername", this.username);
        this.username = null;
        this.makeToast("success", result.message);
      }
    },
  },
};
</script>