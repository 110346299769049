<template>
  <div class="section-exchange-points mt-3 mt-md-0" ref="bodyContent">
    <b-card
      v-if="!isLoading && !cardLazyLoad.length"
      no-body
      class="overflow-hidden rounded-0 border-0 shadow-sm mb-3"
    >
      <b-row no-gutters>
        <b-col md="12" lg="12">
          <b-card-body>
            <b-card-title class="text-center">利用できないカード</b-card-title>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
      v-for="card in cardLazyLoad"
      :key="card.cardId"
      class="overflow-hidden rounded-0 border-0 shadow-sm mb-3"
    >
      <b-row no-gutters>
        <b-col md="4" lg="3" class="border-right">
          <b-card-img-lazy
            :src="card.picture"
            alt="Image"
            class="rounded-0"
          ></b-card-img-lazy>
        </b-col>
        <b-col md="8" lg="9">
          <b-card-body class="pl-xxl-5">
            <b-card-title class="font-weight-bold">{{ card.title }}</b-card-title>
            <b-card-text>
              <span class="label font-weight-bold">ドネト</span>
              <span class="point font-weight-bold">{{ card.numberPoints }}PT</span>
            </b-card-text>
            <b-button
              title="交換する"
              class="btn-slash font-weight-bold"
              @click="showMsgExchangePoint(card)"
            >
              <span class="position-relative">交換する</span>
              <esports-loading-button
                width="30px"
                height="30px"
                outerBorder="#333"
                innerBorder="#eee"
                v-if="card.isLoading"
              />
            </b-button>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <template>
      <p class="text-center position-relative" v-if="isLazyLoading">
        <esports-loading-button width="30px" height="30px" />
      </p>
    </template>
    <esports-loading-button
      class="overlay-content-tab-page"
      width="80"
      height="80"
      v-if="isLoading"
    />
  </div>
</template>

<script>
export default {
  name: "TabExchangePoint",
  data() {
    return {
      cardHistory: [],
      dataContent: null,
      isLoading: false,
      isLazyLoading: false,
      isLoadingItem: false,
      page: 1,
      perPage: 20,
    };
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.onScroll);
      this.onScroll();
    });

    this.isLoading = true;
    await this.getCardHistory({ page: this.page, perPage: this.perPage });
    this.isLoading = false;
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  computed: {
    cardLazyLoad() {
      return this.cardHistory || [];
    },
  },
  methods: {
    async getCardHistory(params) {
      const result = await this.$store
        .dispatch("point_exchange/getCardHistory", params)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        this.cardHistory = result.data.map((item) => {
          item.isLoading = false;
          item.picture = `${process.env.VUE_APP_BACKEND + item.picture}`;
          item.numberPoints = this.formatPoint(item.numberPoints);
          return item;
        });
        this.dataContent = result;
      }
    },
    async onScroll() {
      const vm = this;
      if (vm.$refs.bodyContent) {
        let bottom = vm.$refs.bodyContent.getBoundingClientRect().bottom;

        if (
          bottom < window.scrollY &&
          !this.isLazyLoading &&
          this.dataContent?.data.length
        ) {
          this.isLazyLoading = true;
          this.page += 1;

          this.dataContent = await this.$store
            .dispatch("point_exchange/getCardHistory", {
              page: this.page,
              perPage: this.perPage,
            })
            .catch((err) => {
              console.log(err);
              this.isLazyLoading = false;
            });

          if (this.dataContent?.data.length) {
            let dataList = this.dataContent.data.map((item) => {
              item.isLoading = false;
              item.picture = `${process.env.VUE_APP_BACKEND + item.picture}`;
              item.numberPoints = this.formatPoint(item.numberPoints);
              return item;
            });
            this.cardHistory = this.cardHistory.concat(dataList);
          }

          this.isLazyLoading = false;
        }
      }
    },
    async exchangePointCard(card) {
      let cardId = card.cardId;
      const result = await this.$store
        .dispatch("point_exchange/exchangePointCard", { cardId })
        .catch((err) => {
          card.isLoading = false;

          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }

          this.makeToast('danger', this.msgErrors[0])

        });

      if (result) {
        this.makeToast('success', result.message);
      }

      card.isLoading = false;
    },
    showMsgExchangePoint(card) {
      this.$bvModal
        .msgBoxConfirm("こちらのギフト券に交換します。よろしいですか？", {
          title: "確認してください",
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          bodyClass: "text-center",
          headerClass: "py-2",
          dialogClass: "msgBoxModal",
          hideHeader: true,
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            card.isLoading = true;
            this.exchangePointCard(card);
          }
        });
    },
  },
};
</script>