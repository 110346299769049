<template>
  <div class="my-page w-100">
    <b-container
      class="container-xlg pl-xxl-0 pr-xxl-0 pb-2 pb-xxl-5 mt-3 mt-md-4"
      v-if="!isTopPage"
    >
      <b-tabs
        pills
        card
        v-model="tab"
        vertical
        nav-wrapper-class="col-nav"
        class="pt-md-2"
        nav-class="p-0"
      >
        <b-tab lazy>
          <template #title>
            <span>マイページTOP</span>
          </template>
          <div class="tab-body-content">
            <TapTop @redirectPointHistory="redirectPointHistory" />
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <span>投票履歴</span>
          </template>
          <div class="tab-body-content">
            <TabVoteHistory @redirectPointHistory="redirectPointHistory" />
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <span>投票取得履歴</span>
          </template>
          <div class="tab-body-content">
            <TabBuyVote @redirectPointHistory="redirectPointHistory" />
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <span>ドネト履歴</span>
          </template>
          <div class="tab-body-content">
            <TabPointHistory />
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <span>ドネト交換</span>
          </template>
          <div class="tab-body-content">
            <TabExchangePoint />
          </div>
        </b-tab>
        <b-tab v-if="user && !user.loginMethod" lazy>
          <template #title>
            <span>パスワード変更</span>
          </template>
          <div class="tab-body-content">
            <TabChangePassword />
          </div>
        </b-tab>
        <!-- <b-tab lazy>
          <template #title>
            <span>選手登録申請</span>
          </template>
          <div class="tab-body-content">
            <TabGamer
              v-if="user && !user.createdGamer"
              @redirectPointHistory="redirectPointHistory"
            />
            <div
              v-else
              class="
                section-invite section-register-gamer
                mt-3 mt-md-0
                py-3 py-xxl-5
                bg-white
                shadow-sm
              "
            >
              <h2 class="font-weight-bold">
                <span>選手登録がすでに完了しています。</span>
              </h2>
            </div>
          </div>
        </b-tab> -->
        <b-tab lazy>
          <template #title>
            <span>友達紹介</span>
          </template>
          <div class="tab-body-content">
            <TabInvite />
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <span>退会フォーム</span>
          </template>
          <div class="tab-body-content">
            <TabRemoveUser @removeUser="removeUser" />
          </div>
        </b-tab>
      </b-tabs>
    </b-container>
    <UserRemoveThird v-else />
    <SectionPhone class="mt-5 pb-3 pt-3 pt-md-5 pb-md-5 pb-xxl-5 bg-white" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SectionPhone from "@/views/Landing/SectionPhone";
import TapTop from "./TabTop";
import TabExchangePoint from "./TabExchangePoint";
import TabInvite from "./TabInvite";
import TabPointHistory from "./TabPointHistory";
import TabChangePassword from "./TabChangePassword";
import TabVoteHistory from "./TabVoteHistory";
import TabBuyVote from "./TabBuyVote";
//import TabGamer from "./TabGamer";
import TabRemoveUser from "./TabRemoveUser";
import UserRemoveThird from "./UserRemove/UserRemoveThird";

export default {
  name: "MyPage",
  title: "my_page",
  components: {
    SectionPhone,
    TapTop,
    TabExchangePoint,
    TabInvite,
    TabPointHistory,
    TabChangePassword,
    TabVoteHistory,
    TabBuyVote,
    //TabGamer,
    TabRemoveUser,
    UserRemoveThird
  },
  data() {
    return {
      tab: 0,
      isTopPage: false
    };
  },
  watch: {
    tab(nVAl) {
      this.scrollToTop();
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    redirectPointHistory(tab) {
      this.tab = tab;
      this.scrollToTop();
    },
    async removeUser() {
      this.isTopPage = true;
      await this.$store.commit('auth/logout');
    }
  },
};
</script>