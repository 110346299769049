<template>
  <div
    class="
      section-history
      shadow-sm
      bg-white
      mt-3 mt-xxl-4
      py-3
      pt-xxl-4
      position-relative
    "
  >
    <HeaderTop title="ドネト履歴" class="ml-3 mr-4 ml-xxl-4 mb-3" :isHideViewAll='true'>
      <b-link v-if="!isViewAll" slot="href" class="mr-xxl-3" @click="redirectPointHistory" title="View All">View All</b-link>
    </HeaderTop>
    <div class="table-content">
      <b-table hover :items="pointHistory" class="position-relative" :fields="fields">
        <template #cell(createdDate)="row">
            {{ formatDate(row.item.createdDate) }}
        </template>
        <template #cell(numberPoints)="row">
            {{ row.item.numberPoints }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import HeaderTop from "./HeaderTop";
export default {
  name: "HistoryPoint",
  components: { HeaderTop },
  props: {
      pointHistory: {
          type: [Array],
          default: () => []
      },
      isViewAll: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      fields: [
        {
          key: "createdDate",
          label: "日時",
          class: "w-25"
        },
        {
          key: "title",
          label: "タイトル",
          class: 'w-50 w-mobile'
        },
        {
          key: "numberPoints",
          label: "獲得ドネト",
          class: 'avatar'
        },
      ],
    };
  },
  methods: {
    redirectPointHistory() {
      this.$emit('redirectPointHistory', 3);
    }
  }
};
</script>