<template>
  <div class="section-invite mt-3 mt-md-0 py-3 py-xxl-5 bg-white shadow-sm">
    <h2 class="font-weight-bold">
      <span>お友達を招待するとお友達に1票の投票チケット</span
      ><span>あなたに1票の投票チケットをプレゼント！</span>
    </h2>
    <p class="text-center py-3 px-md-3"><esports-icon-hand /></p>
    <b-form
      class="form-inputs px-3 px-xl-4 px-xxl-5"
      novalidate
      @submit.prevent="onSubmitInvite"
    >
      <b-row>
        <b-col md="12" lg="3"></b-col>
        <b-col md="12" lg="9">
          <transition-alert>
            <template v-if="msgErrors.length">
              <b-alert
                :show="dismissCountDown"
                dismissible
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChange"
                variant="danger"
              >
                <span
                  class="d-block"
                  v-for="(msg, index) in msgErrors"
                  :key="index"
                  >{{ msg }}</span
                >
              </b-alert>
            </template>
          </transition-alert>
          <transition-alert>
            <template v-if="msgSuccess">
              <b-alert
                :show="dismissCountDown"
                dismissible
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChange"
                variant="success"
              >
                {{ msgSuccess }}
              </b-alert>
            </template>
          </transition-alert>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col md="12" lg="3">
          <label for="input-email" class="font-weight-bold">メールアドレス</label>
        </b-col>
        <b-col md="12" lg="9">
          <b-form-input
            id="input-email"
            autocomplete="off"
            type="text"
            v-model="inviteForm.email"
          ></b-form-input>
          <span class="d-block note mt-2">半角英数200文字以内に入力</span>
        </b-col>
      </b-row>
      <b-row class="py-2 py-md-3">
        <b-col md="12" lg="3">
          <label for="input-message" class="font-weight-bold">メッセージ</label>
        </b-col>
        <b-col md="12" lg="9">
          <b-form-textarea
            id="input-message"
            v-model="inviteForm.message"
            rows="3"
            max-rows="6"
            class="mb-2 mb-md-3 mb-xxl-4"
          ></b-form-textarea>

          <b-button class="btn-slash mt-3" type="submit" :disabled="isSubmitted"
            ><span class="position-relative">招待する</span>
            <esports-loading-button
              v-if="isSubmitted"
              width="30px"
              height="30px"
              outerBorder="#333"
              innerBorder="#eee"
          /></b-button>
          <p class="text-note mt-3">
            <span
              >ご招待いただいたお友達には、入会時にもれなく1票の投票チケットをプレゼント!</span
            >
            <span
              >ぜひたくさんのお友達を誘って一緒にプロゲーマーを応援しましょう。</span
            >
          </p>
        </b-col>
      </b-row>
    </b-form>

    <!-- modal add ticket -->
    <b-modal
      ref="add_ticket_modal"
      content-class="content-vote-twitter"
      body-class="position-relative p-0 d-flex align-items-center justify-content-center"
      hide-footer
      hide-header
      size="sm"
      centered
    >
      <div class="vote-twitter text-center">
        <h5 class="mb-4 txt-indent-number text-center">
          友達招待で投票チケット<br /><span class="font-weight-bold">1票</span>獲得しました。
        </h5>
        <b-button
          class="font-weight-bold"
          @click="hideModal('add_ticket_modal')"
          >閉じる</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { dismissCount } from "@/mixins";

export default {
  name: "TabInvite",
  data() {
    return {
      isSubmitted: false,
      msgErrors: [],
      msgSuccess: null,
      inviteForm: {
        email: "",
        message: "",
      },
    };
  },
  watch: {
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  mixins: [dismissCount],
  methods: {
    async onSubmitInvite() {
      this.isSubmitted = true;
      this.msgSuccess = null;

      const result = await this.$store
        .dispatch("user/inviteFriend", this.inviteForm)
        .catch((err) => {
          this.isSubmitted = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      this.isSubmitted = false;

      if (result) {
        this.msgSuccess = result.message;
        this.msgErrors = [];
        this.inviteForm.email = "";
        this.inviteForm.message = "";

        //this.showModal("add_ticket_modal");
      }
    },
    async showModal(name) {
      this.$refs[name].show();

      const dataUser = await this.$store.dispatch("user/getMyPage");
      if(dataUser) this.$store.commit("auth/updateUserProfile", dataUser);

    },
    hideModal(name) {
      this.$refs[name].hide();
    },
  },
};
</script>