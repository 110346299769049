<template>
  <div class="mt--3">
    <HistoryVoted :voteHistory='voteHistory' :isViewAll="true" />
    <esports-loading-button
      class="overlay-content-tab-page"
      width="80"
      height="80"
      v-if="isLoading"
    />
  </div>
</template>

<script>
import HistoryVoted from "./MyTop/HistoryVoted";
export default {
  name: "TabVoteHistory",
  data() {
      return {
        isLoading: false,
        voteHistory: []
      }
  },
  components: { HistoryVoted },
  async mounted() {
    this.isLoading = true
    await Promise.race([
      this.getAllHistoryVote(),
    ]);
    this.isLoading = false;
  },
  methods: {
    async getAllHistoryVote() {
      const result = await this.$store
        .dispatch("vote/getAllHistoryVote")
        .catch((err) => {
          console.log(err);
        });

      if(result) {
        this.voteHistory = result.map(item => {
          item.picture = item.picture ? `${process.env.VUE_APP_BACKEND + item.picture}` : null;
          return item;
        });
      }
    },
  },
};
</script>