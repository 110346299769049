<template>
  <div class="header-top d-flex justify-content-between align-items-center">
      <span>{{ title }}</span>
      <b-link v-if="!isHideViewAll" class="mr-xxl-3" :href="anchor" :title="anchorText">{{ anchorText }}</b-link>
      <slot name="href"></slot>
  </div>
</template>

<script>
export default {
    name: "HeaderTop",
    props: {
        title: {
            type: String,
            default: 'Title'
        },
        anchor: {
            type: String,
            default: '#'
        },
        anchorText: {
            type: String,
            default: 'View All'
        },
        isHideViewAll: {
            type: Boolean,
            default: false
        }
    }
}
</script>